.header {
    position: fixed;
    height: 47px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.3s ease-in;
    overflow: hidden;
    background-color: rgba(54, 52, 52, 0.9);
  }
  
  .header .navbar {
    display: flex;
    justify-content: center; /* Center the items horizontally */
    align-items: center; /* Center the items vertically */
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
  }
  
  .header .nav-menu a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .header .navbar img {
    width: 250px;
    height: auto;
  }
  
  .header .nav-menu {
    display: flex;
    list-style: none;
  }
  
  .header .nav-item {
    padding: 1rem;
    font-weight: 500;
  }
  
  .nav-item .active {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
  }
  
  .header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
  }
  
  .hamburger {
    display: none;
  }
  
  .header .navbar .hamburger {
    margin-left: 1rem; /* Keep the hamburger menu aligned to the left */
  }
  
  @media screen and (max-width: 940px) {
    .header {
      max-width: 100%;
      background-color: rgba(0, 0, 0, 0.9);
    }
  
    .header .navbar {
      max-width: 100%;
    }
  
    .hamburger {
      display: block;
      margin-left: 1rem; /* Keep the hamburger menu aligned to the left */
      margin-right: auto;
    }
  
    .nav-menu {
      position: fixed;
      left: -120%;
      top: 90px;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 90vh;
      z-index: 999;
      text-align: center; /* Center the navigation items horizontally */
      justify-content: center; /* Center the navigation items vertically */
      transition: 0.7s;
      align-items: center;
    }
  
    .nav-menu.active {
      left: 0;
    }
  
    .nav-item {
      margin: 1.5rem 0;
    }
    .nav-item a {
      padding-right: 70px;
    }
  
    .header .navbar img {
      width: 150px;
    }
  }
  
  .header .nav-menu a:hover {
    color:#ccb6f2;
  }