@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans&display=swap');

.footer {
    background-color: #1c1c1c;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    border-top: 1px solid #444;
}

.footerText {
    color: #ccb6f2;
    font-size: 26px;
    margin: 0;
    font-family: 'Pixelify Sans', sans-serif;
}

.footerEmail {
    color: #ccb6f2;
    font-size: 26px;
    padding-top: 20px;
    margin: 0;
    font-family: 'Pixelify Sans', sans-serif;
}

.footerLink {
    color: white;
    text-decoration: none;
}

.footerLink:hover {
    text-decoration: underline;
}
