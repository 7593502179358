/* Existing CSS */
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans&display=swap');




.title{
    font-family: 'Pixelify Sans', sans-serif; /* Use the imported font */
}


.about {
    background-image: url('../images/black.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
     padding: 0;
     width: 100%;
   }
   
   .about .container {
     width: 1240px;
     margin: auto;
 
     display: grid;
     grid-template-columns: repeat(2, 1fr);
   }
   
   .container img {
     margin-top: 100px;
     margin-bottom: 100px;
     width: 100%;
     max-width: 500px;
     height: auto;
     border-radius: 40px;
     
   }
   
   .about h2 {
     font-size: 3rem;
   }
   
   .about .col-2 {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding: 3rem;
   }
   
   .about .col-2 p:first-of-type {
     margin: 2rem 0;
   }
   
   .about .col-2 p:nth-child(4) {
     font-size: 1.8rem;
     font-style: italic;
     font-weight: 600;
     
   }
 
   .about h1,
 .about h2,
 .about h3,
 .about p {
   color: white;
 }
   
   .about button {
     margin-top: 1rem;
   }
   
   .about .icons {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     margin-top: 1rem;
   }
   
   .about .icons img {
     height: 40px;
     margin: 0.5rem;
     flex: 0 0 calc(16.666% - 1rem);/* Adjust to evenly space out 5 icons per row */
   }
   
   .about .icons img:last-child {
     margin-right: 0;
   }
   
   @media screen and (max-width: 500px) {
     .about .container {
       grid-template-columns: 1fr;
     }
   
     .about .container {
       width: 100%;
     }
   
     .about .container img {
       padding: 1rem;
       width: 90vw;
     }
   
     .about .container .col-2 {
       padding: 0;
     }
   
     .about button {
       margin-bottom: 1rem;
     }
   }
   